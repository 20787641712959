import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { twJoin, twMerge } from '../utils';
import { generateCustomId } from '@app/shared/utils/custom-id';

export interface DropdownRowItem {
  id: string | number;
  name: string;
  secondaryName?: string;
  badgeLabel?: string;
  className?: string;
  iconName?: string;
  disabled?: boolean;
}

@Component({
  selector: 'da-dropdown',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DropdownComponent),
    },
  ],
  template: `
    <div
      [class]="
        twMerge([
          'tw-group tw-relative tw-block',
          selectedItem ? 'has-selection' : '',
        ])
      "
    >
      <button
        type="button"
        [cdkMenuTriggerFor]="context_menu"
        [title]="iconOnly ? selectedItem?.name || placeholder : undefined"
        [attr.aria-disabled]="disabled"
        [attr.aria-labelledby]="ariaLabelledbyClasses"
        [attr.aria-describedby]="ariaDescribedby"
        [class]="
          twMerge(
            ['tw-btn-secondary-outline group-[.has-error]:tw-ring-red-800'],
            className
          )
        "
      >
        <span
          [id]="'da-dropdown-value-' + uid"
          [class]="iconOnly ? 'tw-sr-only' : ''"
          >{{ selectedItem?.name || placeholder }}
          <span *ngIf="selectedItem?.secondaryName">
            &bull; {{ selectedItem?.secondaryName }}
          </span>
        </span>
        <da-icon [icon]="iconName" class="tw-size-4"></da-icon>
      </button>
      <label
        [id]="'da-dropdown-label-' + uid"
        [class]="
          'tw-absolute tw-hidden tw-origin-[0] tw-transform tw-cursor-text tw-rounded-sm tw-bg-white tw-p-[2px] tw-text-xs tw-text-neutral-600 tw-duration-300 group-hover:tw-text-neutral-800 group-[.has-selection]:-tw-top-3 group-[.has-selection]:tw-left-2 group-[.has-selection]:tw-inline-block group-[.has-error]:tw-text-red-800 group-hover:group-[.has-error]:tw-text-red-800'
        "
        >{{ placeholder }}</label
      >
    </div>

    <ng-template #context_menu>
      <div cdkMenu>
        <ul
          class="tw-reset tw-flex tw-max-h-80 tw-min-w-60 tw-origin-top tw-flex-col tw-overflow-y-auto tw-rounded-lg tw-bg-white tw-py-2 tw-shadow-xl tw-ring-1 tw-ring-neutral-200 tw-transition"
          [id]="'da-dropdown-menu-id' + uid"
        >
          <li *ngFor="let item of items">
            <button
              cdkMenuItem
              type="button"
              [class]="
                twMerge(
                  'tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-3 tw-px-4 tw-py-2 tw-text-left !tw-text-[16px]  !tw-text-neutral-800 hover:tw-bg-neutral-100 focus-visible:tw-outline-none',
                  disabled || item?.disabled
                    ? 'tw-cursor-not-allowed tw-opacity-50'
                    : 'hover:tw-bg-neutral-100'
                )
              "
              [attr.aria-disabled]="item?.disabled"
              (cdkMenuItemTriggered)="handleItemSelect(item)"
            >
              <div
                class="pr-10 text-left tw-leading-5"
                [class]="
                  twJoin(
                    selectedItem?.id === item.id ? 'tw-font-semibold' : '',
                    item?.className
                  )
                "
              >
                <span
                  class="tw-block tw-max-w-48 tw-truncate"
                  title="{{ item.name }}"
                >
                  <span class="tw-flex tw-items-center tw-gap-x-2"
                    ><da-icon
                      *ngIf="item.iconName"
                      icon="{{ item.iconName }}"
                      className="tw-size-4"
                    ></da-icon>
                    {{ item.name }}
                  </span>
                </span>
                <span class="tw-text-xs tw-text-neutral-600">
                  {{ item?.secondaryName }}
                </span>
              </div>

              <div
                class="tw-flex tw-items-center tw-gap-3"
                *ngIf="selectedItem?.id === item.id"
              >
                <span
                  *ngIf="item?.badgeLabel"
                  class="tw-rounded-2xl tw-border tw-border-purple-300 tw-bg-purple-50 tw-px-2 tw-py-1 tw-text-xs tw-font-extrabold tw-uppercase tw-text-purple-800"
                >
                  {{ item.badgeLabel }}
                </span>
                <da-icon
                  icon="check-circle"
                  type="solid"
                  class="tw-size-5 tw-text-green-600"
                ></da-icon>
              </div>
            </button>
          </li>
        </ul>
      </div>
    </ng-template>
  `,
  animations: [
    trigger('PopoverTrigger', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(95%)' }),
        animate(
          '200ms ease-out',
          style({ opacity: 1, transform: 'scale(100%)' })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(100%)' }),
        animate(
          '100ms ease-in',
          style({ opacity: 0, transform: 'scale(95%)' })
        ),
      ]),
    ]),
  ],
})
export class DropdownComponent implements ControlValueAccessor {
  @Input() items!: DropdownRowItem[];
  @Input() className?: string;
  @Input() ariaLabelledby?: string = '';
  @Input() ariaDescribedby?: string = '';
  @Input() placeholder?: string;
  @Input() selectedItem: DropdownRowItem | null = null;
  @Input() iconName: string = 'chevron-down';
  @Input() autoReset: boolean = false;
  @Input() disabled: boolean = false;
  @Input() iconOnly: boolean = false;
  @Output() itemSelect = new EventEmitter<DropdownRowItem>();

  open = false;
  twJoin = twJoin;
  twMerge = twMerge;
  uid = generateCustomId();

  handleItemSelect(item: DropdownRowItem) {
    // since we should always use aria-disabled, we have to
    // handle the disabled state and not do anything on click
    // if the item is disabled
    // also just treat every item as disabled if the menu is disabled
    if (this.disabled || item.disabled) {
      return;
    }

    this.onChange(item);

    this.selectedItem = this.autoReset ? null : item;

    this.itemSelect.emit(item);
    this.open = false;
  }

  /*
   * Implement ControlValueAccessor interface methods
   */
  onChange: (value: any) => void = () => {};
  onTouched: (value: any) => void = () => {};

  public get ariaLabelledbyClasses() {
    let labels = `da-dropdown-label-${this.uid} `;

    // preventing the placeholder getting read out multiple times
    // by only adding the value in if there's one selected
    if (this.selectedItem) {
      labels += `da-dropdown-value-${this.uid} `;
    }

    return labels + this.ariaLabelledby;
  }

  public writeValue(value: DropdownRowItem): void {
    this.selectedItem = value;
    this.onChange(this.selectedItem);
    this.cdr.detectChanges();
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  constructor(private cdr: ChangeDetectorRef) {}
}
