import { Component, EventEmitter, Input, Output } from '@angular/core';

import { twJoin, twMerge } from '../../../../utils';
import { LayoutFeature, LayoutNavigationItem } from '../../layout.model';

@Component({
  selector: 'da-nav-item',
  template: `
    <ng-container *ngIf="item.routerLink; else externalLink">
      <a
        [routerLink]="item.routerLink"
        [class]="
          twMerge(
            'tw-relative tw-flex tw-items-center tw-px-7 tw-py-3 tw-text-xs',
            'hover:tw-bg-[rgb(from_var(--apollo-layout-highlight)_r_g_b_/_15%)] hover:tw-text-[var(--apollo-layout-highlight)]',
            'focus:tw-bg-[rgb(from_var(--apollo-layout-highlight)_r_g_b_/_15%)] focus:tw-text-[var(--apollo-layout-highlight)] focus:tw-outline-none',
            'active:tw-bg-[rgb(from_var(--apollo-layout-highlight)_r_g_b_/_30%)] active:tw-text-[var(--apollo-layout-highlight)]',
            active
              ? 'tw-font-semibold tw-text-[var(--apollo-layout-highlight)]'
              : '',
            previewHover
              ? 'tw-bg-[rgb(from_var(--apollo-layout-highlight)_r_g_b_/_15%)] tw-text-[var(--apollo-layout-highlight)]'
              : '',
            className
          )
        "
        [title]="item.text"
        [attr.data-dgat]="item.dgat"
        (click)="handleClick(item)"
      >
        <ng-container *ngTemplateOutlet="linkContent"></ng-container>
      </a>
    </ng-container>

    <ng-template #externalLink>
      <a
        [href]="item.href"
        [attr.target]="item.target"
        [class]="
          twMerge(
            'tw-relative tw-flex tw-items-center tw-py-3 tw-pl-7 tw-pr-3 tw-text-xs rtl:tw-pl-3 rtl:tw-pr-7',
            'hover:tw-bg-[rgb(from_var(--apollo-layout-highlight)_r_g_b_/_15%)] hover:tw-text-[var(--apollo-layout-highlight)]',
            'focus:tw-bg-[rgb(from_var(--apollo-layout-highlight)_r_g_b_/_15%)] focus:tw-text-[var(--apollo-layout-highlight)] focus:tw-outline-none',
            'active:tw-bg-[rgb(from_var(--apollo-layout-highlight)_r_g_b_/_30%)] active:tw-text-[var(--apollo-layout-highlight)]',
            active
              ? 'tw-font-semibold tw-text-[var(--apollo-layout-highlight)]'
              : '',
            previewHover
              ? 'tw-bg-[rgb(from_var(--apollo-layout-highlight)_r_g_b_/_15%)] tw-text-[var(--apollo-layout-highlight)]'
              : '',
            className
          )
        "
        [title]="item.text"
        [attr.data-dgat]="item.dgat"
        (click)="handleClick(item)"
      >
        <ng-container *ngTemplateOutlet="linkContent"></ng-container>
      </a>
    </ng-template>

    <ng-template #linkContent>
      <da-nav-icon
        [item]="item"
        [active]="active"
        [className]="
          twJoin(
            'tw-size-6 tw-shrink-0 tw-min-w-6',
            iconOnly ? '' : 'tw-mr-2 rtl:tw-mr-0 rtl:tw-ml-2',
            item.rtlMirrorIcon ? 'rtl:tw-scale-x-[-1]' : ''
          )
        "
      />

      <span
        [class]="
          twJoin(
            iconOnly ? 'tw-sr-only' : 'tw-truncate',
            beta?.enabled && item.isBeta ? '' : 'tw-grow'
          )
        "
        >{{ item.text }}</span
      >

      <div
        *ngIf="beta?.enabled && item.isBeta"
        [class]="
          twJoin(
            'tw-grow',
            iconOnly
              ? 'tw-absolute tw-right-2 tw-top-1 rtl:tw-left-2 rtl:tw-right-auto'
              : 'tw--mt-2 tw-flex tw-self-start'
          )
        "
      >
        <div
          [class]="
            twJoin(
              'tw-rounded-2xl tw-border tw-border-purple-300 tw-bg-purple-100 tw-px-1 tw-py-0.5 tw-text-[8px] tw-font-extrabold tw-uppercase tw-leading-3 tw-text-purple-800'
            )
          "
        >
          {{ beta.text }}
        </div>
      </div>

      <div
        *ngIf="active"
        [class]="
          twJoin(
            'tw-absolute tw-inset-y-0 tw-right-0 tw-w-1 tw-rounded-l tw-bg-[var(--apollo-layout-highlight)] rtl:tw-left-0 rtl:tw-right-auto rtl:tw-rounded-l-none rtl:tw-rounded-r'
          )
        "
      ></div>
    </ng-template>
  `,
})
export class NavItemComponent {
  @Input() item!: LayoutNavigationItem;
  @Input() iconOnly? = false;
  @Input() beta?: LayoutFeature;
  @Input() className = '';
  @Input() active = false;
  @Input() previewHover = false;
  @Output() itemSelect = new EventEmitter<LayoutNavigationItem>();

  twMerge = twMerge;
  twJoin = twJoin;

  /**
   * User clicked (or keyboard event) to navigate
   * Notify parent component and report the event
   */
  handleClick(item: LayoutNavigationItem): void {
    item.trackEvent?.();
    this.itemSelect.emit(item);
  }
}
