import { HostType } from '@app/content-hosting';
import { DatePipe } from '@angular/common';
import { Inject, SecurityContext } from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeValue,
} from '@angular/platform-browser';
import { InputResourceType } from '@app/inputs/inputs.enums';
import { MarkdownService } from '@app/markdown/services/markdown.service';
import { PathwaySummaryModel } from '@app/pathways/pathway-api.model';
import {
  InputType,
  LearningResource,
  LearningResourceType,
  Providable,
  RecommendationInfo,
  ResourcePrivacyLevel,
  TargetType,
} from '@app/shared/models/core-api.model';
import { Trackable } from '@app/shared/models/trackable';
import { DisplayTypePipe } from '@app/shared/pipes/display-type.pipe';
import { HtmlToPlaintextPipe } from '@app/shared/pipes/htmlToPlaintext.pipe';
import { appendQueryParams, pascalCaseKeys } from '@app/shared/utils/property';
import { TagsApi } from '@app/tags/tag-api.model';
import { Target } from '@app/target/target-api.model';
import { LDFlagsService } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { pick } from 'lodash-es';
import {
  Input,
  InputDetails,
  InputSession,
  InputStatistics,
  MarketplaceProgram,
  VideoType,
} from '../inputs-api.model';
import { VideoService } from '../services/video.service';
import { UserCompletionInfo } from './user-completion-info';
import { capitalizeFirstLetter } from '@app/shared/utils';
import { buildAcademyUrl } from '@app/academies/utils/academy.utils';
import { PathwayStepDetails } from '@app/pathways/rsm/pathway-api.model';

export type AnyLearningResource = Input &
  InputDetails &
  PathwaySummaryModel &
  Target &
  MarketplaceProgram;

export interface VideoInfo {
  videoUrl: string; // Provided for backward compatibility with angularjs. TODO: remove when no ngjs components need to bind to it.
  trustedVideoUrl: SafeValue;
  videoType: VideoType;
  contentUrl: string;
  externalId: string;
  thumbnailUrl: SafeResourceUrl; // only supported by Youtube videos
}

export interface ProviderSummary {
  name: string;
  id: number;
  code: string;
  url: string;
  image: string;
  logo: string;
}

export interface MetaData {
  cost?: string;
  durationDisplay?: string;
  durationHours?: number;
  durationMinutes?: number;
  providerSummaryName?: string;
  providerId?: number;
  resourceLabel?: string;
  isVerified?: boolean;
  subtitle?: string;
  tagsCountDisplay?: string;
  difficulty?: string;
  format?: string;
  continuingEducationUnits?: string;
  programLength?: number;
  programLengthUnit?: string;
}

export interface ProviderLearningResource extends LearningResource, Providable {
  pathwayStepDetails?: PathwayStepDetails;
  isCompleted?: boolean;
  liveSessions?: any;
  tags?: string | TagsApi.Tag[];
  hostedType?: string;
  userHasCommented?: boolean;
  obsolete?: boolean;
  inputType?: InputType;
  inputId?: number;
  format?: string;
  userInputId?: number;
  marketplaceInputId?: string;
  status?: string;
}

/** A presentation model for components that display Learning Resources or other classes that need normalized data for those resources.
 * This is analogous to the {@link InputComponent} interface and {@link ContentSvc} in the angularjs code.
 */
export class LearningResourceViewModel
  extends Trackable<LearningResourceViewModel>
  implements LearningResource
{
  public readonly title: string;
  public readonly internalUrl: string;
  public readonly url: string;
  public readonly publicUrl: string;
  public readonly trustedUrl: SafeValue;
  public readonly imageUrl: string;
  public readonly isInputCollection: boolean;
  public readonly normalizedContentId: number;
  public readonly normalizedContentType: LearningResourceType;
  public readonly displayType: string;
  public readonly providerSummary: ProviderSummary;
  public readonly supportsMarkdown: boolean;
  public readonly orgName: string;
  public readonly organizationId: number;
  public readonly pathwayStepDetails?: PathwayStepDetails;
  public readonly isViewed?: boolean;
  public readonly percentComplete?: number;
  public readonly privacyLevel?: ResourcePrivacyLevel;
  public readonly isFollowable: boolean;
  public readonly targetType?: TargetType;
  public readonly tagNames?: string[];
  public readonly dateRange?: string;
  public readonly continuingEducationUnits?: number;
  public readonly isVerified?: boolean;
  public readonly authored?: boolean;
  public readonly authorship?: string;
  public readonly requiredDueDate?: string;
  public readonly assignedDueDate?: string;
  public readonly narrator?: string;
  public readonly liveSessions?: InputSession[];
  public readonly isLive?: boolean;
  public readonly isRegistered?: boolean;
  ////// POC - Related Content Recommendations
  public readonly hasRelatedContent?: boolean;
  public readonly sessionCount?: number;
  public readonly hostedType?: HostType;
  public readonly reference?: PathwaySummaryModel;
  public readonly publishDate?: string;
  public isEndorsed: boolean;
  public externalUrl: string;
  public isQueued: boolean;
  public queueItemId: number;
  public isEnrolled?: boolean;
  public isFollowing?: boolean;
  public isFeatured?: boolean;
  public isSelected?: boolean;
  public isCompleted?: boolean;
  public pathwayId?: number;
  public statistics?: InputStatistics;
  public requestingUserRating?: number;
  public recommendationInfo?: RecommendationInfo;
  public metaData: MetaData = {};
  public plainTextSummary: string;

  /* A unique id is required for propertly tracking in state management entity collections.
     It's assigned to `internalUrl` below it gets overwritten in some contexts. */
  public id: any;

  // pathway resources may have display values different from actual values
  public displayTitle?: string;
  public displaySummary?: string;
  public displayImageUrl?: string;

  private _summary: string;
  private _videoInfo: VideoInfo;
  private _completionInfo?: UserCompletionInfo; /// readonly children?
  private _trackingKey: string;
  private _hasUserSummary: boolean;
  private _youtubeEmbeddedVideoCookieEnabled: boolean;

  /** Constructs a view model for a Learning Resource DTO model.
   * @description The original model is frozen upon construction. Note, however, that
   * using any of the property setters or other state manipulation methods will recreate
   * the model with the corresponding changes rather than mutating. Therefore, after
   * construction it's important to use only the view model except for transitive
   * state representation, such as transmitting to an API.
   */
  constructor(
    public model: ProviderLearningResource,
    private displayTypePipe: DisplayTypePipe,
    private htmlToPlaintextPipe: HtmlToPlaintextPipe,
    private markdownService: MarkdownService,
    private sanitizer: DomSanitizer, // state?: InputViewState
    private trackingArea: string,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private videoService: VideoService,
    private ldFlagService: LDFlagsService
  ) {
    super();
    if (!model.pathwayStepDetails) {
      Object.freeze(model); // don't let this change under our feet because we're caching some dependent data
    }
    const m = model as AnyLearningResource;

    this.hostedType = m.hostedType;
    this.tagNames = m.tagNames;
    this.targetType = m.targetType;

    this.internalUrl = m.internalUrl;

    this.url = this.isCourseOrEventWithoutUrl ? m.url : m.url || m.internalUrl;

    if (m.resourceType?.toLowerCase() === 'pathway') {
      this.url = this.internalUrl = `${m.publicUrl}/pathway`;
      this.publicUrl = m.publicUrl;
    }

    if (!!m.pathwayStepDetails) {
      // This is still needed for tracking.
      this.pathwayId = m.pathwayStepDetails.pathwayId;
      this.pathwayStepDetails = m.pathwayStepDetails;
    }

    // Inputs require decorators on the URL
    if (this.internalUrl && InputResourceType.hasOwnProperty(m.resourceType)) {
      // all urls need contentSource
      let params: any = {
        contentSource: this.trackingArea,
      };
      // add pathwayId, if set
      if (!!this.pathwayId) {
        params = {
          ...params,
          contentSourceId: this.pathwayId,
        };
      }
      this.internalUrl = appendQueryParams(m.internalUrl, params);

      if (m.resourceType === 'Post') {
        this.externalUrl = null;
      } else {
        // we don't have a good way to know if it's credspark
        // from results like the content catalog table
        const isCredSpark =
          m.resourceType === 'Assessment' && m.url.includes('credspark://');

        // hosted content is really internal content, but we show a hosted preview
        if (this.isHostedContent && !isCredSpark) {
          this.externalUrl = appendQueryParams(m.internalUrl, {
            hosted: 'true',
            ...params,
          });
          // credspark is also handled internally
        } else if (this.isHarvardSparkEmbeddedContent) {
          this.externalUrl = appendQueryParams(m.internalUrl, {
            HMMEmbeddedUrl: btoa(this.url),
            ...params,
          });
        } else if (this.isAcademy) {
          this.externalUrl = buildAcademyUrl(m.url);
        } else if (isCredSpark) {
          // SDM - This is a bit of a hack, it appears that older credpsark assessments are coming back as hosted
          // we don't handle them as hosted, so ideally the data should be fixed, but for now we'll manually remove
          // the query param from the internal url.
          this.externalUrl = m.internalUrl.replace('&hosted=true', '');
          // everything else goes through the "ViewOriginal" Action on the InputsController
        } else if (!this.isCourseOrEventWithoutUrl) {
          this.externalUrl = `/view/${m.resourceType}/${m.resourceId}`;
        }
      }

      this._youtubeEmbeddedVideoCookieEnabled =
        ldFlagService.inputs.videoInputsEnabledYoutubeCookieTracking;
    }

    this.id = this.internalUrl;

    this.trustedUrl = this.sanitizer.bypassSecurityTrustUrl(this.url);
    this.imageUrl = m.imageUrl || m.image;

    this.isEndorsed = m.isEndorsed;
    this.isCompleted = m.isCompleted;

    // Always populate completion info for inputs, even when canComplete is false, for external content completions
    if (this.isInput) {
      this._completionInfo = new UserCompletionInfo(
        m.userInputId,
        m.externalCompletionOnly
      );
    }

    this.orgName = m.organizationName;
    this.organizationId = m.organizationId;

    this.isQueued = m.isQueued;
    this.queueItemId = m.queueItemId;

    // strip unknown characters (ie. �)
    if (m.title) {
      this.title = m.title.replace(/\uFFFD/g, '');
    }

    this.percentComplete = m.percentComplete || m.progress;

    this.isEnrolled = m.isEnrolled;
    this.privacyLevel = m.privacyLevel ?? m.privacyId;

    // v2 Academies are all set to public
    if (this.isv2Academy) {
      this.privacyLevel = ResourcePrivacyLevel.Public;
    }

    this.isFollowable = m.isFollowable;
    this.isFollowing = m.isFollowing;

    this.isFeatured = m.isFeatured;

    this.dateRange = m.dateRange;
    this.continuingEducationUnits = m.continuingEducationUnits;
    this.isVerified = m.isVerified;
    this.authored = m.authored;
    this.authorship = m.authorship;
    this.narrator = m.narrator;
    this.statistics = m.statistics;
    this.requestingUserRating = m.requestingUserRating;
    this.isViewed = m.isViewed;

    ////// POC - Related Content Recommendations
    this.hasRelatedContent = m.hasRelatedContent;

    if (m.requiredDueDate) {
      this.requiredDueDate = this.datePipe.transform(
        m.requiredDueDate,
        'mediumDate'
      );
    }

    if (m.suggestionDetails?.dueDate) {
      this.assignedDueDate = m.suggestionDetails.dueDate;
    }

    if (m.publishDate) {
      this.publishDate = this.datePipe.transform(m.publishDate, 'mediumDate');
    }

    this.isInputCollection =
      (m.resourceType as LearningResourceType) === 'Pathway' ||
      (m.resourceType as LearningResourceType) === 'Target';

    if (m.providerName) {
      this.providerSummary = {
        id: m.providerId,
        name: m.providerName,
        code: m.providerCode, // (may be undefined)
        url: m.providerUrl,
        image: m.providerImageInfo?.svg,
        logo: m.providerLogo,
      };
    }

    this.metaData = {
      // the order here is important for the order of appearance in the template
      resourceLabel: this.getMetaResourceLabel(),
      isVerified: m.isVerified,
      durationDisplay: m.durationDisplay,
      durationHours: m.durationHours,
      durationMinutes: m.durationMinutes,
      providerSummaryName: m.providerName,
      providerId: m.providerId,
      cost: m.cost,
      tagsCountDisplay: this.getTagsCountDisplay(),
      subtitle: m.subtitle,
      programLength: m.programLength,
      programLengthUnit: m.programLengthUnit,
    };
    // now remove empty items
    for (const item in this.metaData) {
      if (!this.metaData[item]) {
        delete this.metaData[item];
      }
    }

    if (m.continuingEducationUnits) {
      this.metaData.continuingEducationUnits = this.translate.instant(
        'Core_CEUUnits',
        { units: m.continuingEducationUnits }
      );
    }

    if (m.difficulty) {
      // Some integrations/bulk imports provide junk data for difficulty, so we need to filter it
      const allowed = [
        'all level',
        'beginner',
        'intermediate',
        'advanced',
        'basic',
        'expert',
        'normal',
        'undergraduate',
        'graduate',
        'developing',
        'proficient',
      ];

      if (allowed.includes(m.difficulty.toLowerCase())) {
        this.metaData.difficulty = this.translate.instant(
          this.stringToResourceID(m.difficulty)
        );
      }
    }

    if (m.format && m.format !== m.resourceType) {
      // Translate these for Docebo (Google), pass through the rest
      const toTranslate = ['Self-study', 'Workshop', 'Coaching', 'Journey'];

      this.metaData.format = toTranslate.includes(m.format)
        ? this.translate.instant(this.stringToResourceID(m.format))
        : m.format;
    }

    // decisions about live events are made based on whether or not it is undefined, so if it's empty (length === 0) we still don't want it defined
    if (m.liveSessions?.length > 0) {
      this.sessionCount = m.liveSessions.length;
      this.liveSessions = m.liveSessions.map((session) => {
        let startDateFormatted;
        if (session.startDateTime) {
          startDateFormatted = new Date(
            session.startDateTime
          ).toLocaleDateString(navigator.language, {
            day: 'numeric',
            month: 'numeric',
          });
        }
        return { ...session, startDateFormatted };
      });
      this.isLive = m.isLive;
      this.isRegistered = m.isRegistered;
    }

    // Post and Task input types can contain markdown that has to be rendered as HTML.
    // Pathways and Targets support markdown in their description
    this.supportsMarkdown =
      this.resourceType === 'Task' ||
      this.model.resourceType === 'Post' ||
      this.isPathway ||
      this.isTarget;

    const summary = (m.details?.body as string) || m.summary || m.description;

    /**
     * Synchornously returns plain text summary to avoid issues w/ change detection
     *
     * If you need HTML (or Markdown => HTML) and aren't seeing issues w/OnPush
     * change detection giving you blank summary values, use `summary` instead
     */
    this.plainTextSummary = this.markdownService
      .markdownToPlaintext(summary || '')
      ?.replace(/\uFFFD/g, '');
  }

  public getRelatedLearningUrl() {
    if (this.isHarvardSparkEmbeddedContent) {
      return this.externalUrl;
    }

    return this.internalUrl;
  }

  public get resourceId() {
    return this.model.resourceId;
  }

  public set resourceId(value: number) {
    this.refreezeModelWith((newModel) => (newModel.resourceId = value));
  }

  public get resourceType() {
    return this.model.resourceType;
  }

  public get tags() {
    return this.model.tags;
  }

  public get videoInfo() {
    if (!this._videoInfo && this.resourceType === 'Video') {
      this.populateVideoInfo(); // lazy init because parsing video urls is expensive
    }
    return this._videoInfo as Readonly<VideoInfo>;
  }

  public get completionInfo() {
    return this._completionInfo as Readonly<UserCompletionInfo>; // can only use setCompletionState() to modify
  }

  /**
   * Asynchronously return the summary as HTML if supported, otherwise returns plain text
   *
   * Note this is lazily initialized, to allow markdown processing, which can may mean
   * the summary does not resolve before change detection completes
   *
   * Use `plainTextSummary` if you want to ensure you get plain text
   */
  public get summary() {
    if (this._summary === undefined) {
      this.populateSummary(); // lazy init because parsing markdown is expensive
    }
    return this._summary;
  }

  public get hasUserSummary() {
    if (this._summary === undefined) {
      this.populateSummary(); // _hasUserSummary is populated during summary parsing. Lazy init because parsing markdown is expensive.
    }
    return this._hasUserSummary;
  }

  public get canPlayVideo() {
    return !!this.videoInfo?.videoUrl;
  }

  public get trackingKey() {
    if (!this._trackingKey) {
      this._trackingKey =
        this.modelAsAny.inputId?.toString() ||
        'External' + this.modelAsAny.externalId;
    }
    return this._trackingKey;
  }

  // TODO: Remove and remap once Target service has been migrated
  public get pathwayTrackingCopy() {
    return pascalCaseKeys(this.model) as Target;
  }

  public get isInput() {
    return !!(this.model as AnyLearningResource).inputType;
  }

  public get isAcademy() {
    return (
      (this.model as AnyLearningResource)?.format === 'Academy' ||
      (RegExp(/\#\/user\/academies\/welcome\/(\w+)?\?source=lxp/).test(
        (this.model as AnyLearningResource).url
      ) &&
        !this.ldFlagService.showv2Academies)
    );
  }

  /**
   * Determines if resource is new Academy type.
   * We still need to preserve the logic of previous legacy Academies until v2 is rolled in October release.
   * TODO:  Remove the v2 prefix and legacy isAcademy function after new Academies have rolled out in
   * October release. Story to remove legacy code: https://degreedjira.atlassian.net/browse/PD-104826
   */
  public get isv2Academy(): boolean {
    return (this.resourceType as LearningResourceType) === 'Academy';
  }

  /**
   * New status type added for use in Academies
   */
  public get isInProgress(): boolean {
    return this.model?.status === 'InProgress';
  }

  public get isBook() {
    return (this.model as AnyLearningResource)?.inputType === 'Book';
  }

  public get isCourseOrEventWithoutUrl() {
    const model = this.model as AnyLearningResource;
    return (
      (model?.inputType === 'Course' || model?.inputType === 'Event') &&
      !model?.url &&
      !model?.externalId
    );
  }

  public get canComplete() {
    return (
      this.isInput &&
      !(this.model as AnyLearningResource).externalCompletionOnly
    );
  }

  public get canFollow() {
    return this.isPathway || (this.isTarget && this.isFollowable);
  }

  public get isPathway() {
    return (this.resourceType as LearningResourceType) === 'Pathway';
  }

  public get isTarget() {
    return (this.resourceType as LearningResourceType) === 'Target';
  }

  public get targetIsRole() {
    return this.isTarget && this.targetType === 'Role';
  }

  public get targetIsDirectory() {
    return this.isTarget && this.targetType === 'Directory';
  }

  public get dueDate() {
    return this.translate.instant('dgInputTile_DueByFormat', {
      dueDate: this.requiredDueDate,
    });
  }

  private get modelAsAny() {
    return this.model as AnyLearningResource;
  }

  public get isHostedContent(): boolean {
    return this.model.hostedType === HostType.DegreedMedia;
  }

  /**
   * Checks if the given URL is an embedded Harvard Spark content.
   *
   * This method first verifies if the URL belongs to Harvard's platform by checking against known
   * domains (production and QA). If a match is found, it then checks if the URL scheme (protocol) is 'embed'.
   * A combination of both these checks ensures the content is a Harvard Spark embedded link.
   *
   * @returns {boolean}
   * - `true` if the URL is an embedded Harvard Spark content.
   * - `false` otherwise.
   */
  public get isHarvardSparkEmbeddedContent(): boolean {
    return (
      this.model.organizationId &&
      (this.url?.startsWith('embed://platform.hbsp.harvard.edu') ||
        this.url?.startsWith('embed://platform.qa.hbsp.harvard.edu'))
    );
  }

  public getTrackingCopy() {
    const include: (keyof LearningResourceViewModel)[] = [
      'resourceId',
      'resourceType',
      'url',
      'internalUrl',
      'isEndorsed',
      'isCompleted',
      'isInputCollection',
      'pathwayId',
      'isInput',
      'orgName',
      'providerSummary',
      'title',
      'canComplete',
      'metaData',
      'displayType',
      'imageUrl',
      'videoInfo',
      'hasUserSummary',
      'supportsMarkdown',
      'sessionCount',
      'hostedType',
    ];
    return pick(this, include);
  }

  public setCompletionState(userInputId?: number) {
    if (!this.isInput) {
      throw new Error('Only input resources support completion');
    }
    this._completionInfo = new UserCompletionInfo(
      userInputId as number,
      this._completionInfo.externalCompletionOnly
    ); // We can write this directly because the nested object is internally "unfrozen" (and externally readonly)
  }

  private populateSummary() {
    const resource = this.model as AnyLearningResource;
    if (this.supportsMarkdown) {
      // check to see if markdown has already been processed using supportsMarkdown variable
      const resource = this.model as AnyLearningResource;
      const isPost = this.model.resourceType === 'Post';
      const isTask = this.model.resourceType === 'Task';
      let summary: string;
      if (isPost) {
        summary = resource?.details?.body as string;
      }
      summary = summary || resource.summary || resource.description;

      this._summary = this.markdownService.markdownToHtml(summary, {
        allowImages: isPost,
        openLinksInNewWindow: isPost || isTask,
      });
      this._hasUserSummary = true;
    } else {
      this._summary =
        resource.description ||
        this.htmlToPlaintextPipe.transform(
          resource.summary,
          this._hasUserSummary
        );
    }

    if (this._summary) {
      this._summary = this._summary.replace(/\uFFFD/g, '');
    }
  }

  private populateVideoInfo() {
    // embeddable videos checks
    const resource = this.model as AnyLearningResource;
    const videoInfo = {} as VideoInfo;
    if (this.resourceType === 'Video') {
      videoInfo.contentUrl = resource.url;
      videoInfo.videoType = videoInfo.videoType
        ? videoInfo.videoType
        : this.getVideoType(resource.url);

      switch (videoInfo.videoType) {
        case 'youtube':
          videoInfo.externalId = this.parseYouTubeId(resource.url);
          videoInfo.videoUrl = this.getYoutubeEmbedUrl(
            videoInfo.externalId,
            true
          );
          videoInfo.trustedVideoUrl = this.sanitizer.bypassSecurityTrustUrl(
            videoInfo.videoUrl
          );
          videoInfo.thumbnailUrl =
            this.sanitizer.bypassSecurityTrustResourceUrl(
              this.getYoutubeThumbnailUrl(resource.externalId)
            );
          break;
        case 'vimeo':
          videoInfo.externalId = this.getVimeoIdByUrl(resource.url);
          if (videoInfo.externalId) {
            videoInfo.videoUrl = this.getVimeoEmbedUrl(
              videoInfo.externalId,
              this.getVimeoHashByUrl(resource.url),
              true
            );
            videoInfo.trustedVideoUrl = this.sanitizer.bypassSecurityTrustUrl(
              videoInfo.videoUrl
            );
          }
          break;
        case 'degreed':
          videoInfo.videoUrl = videoInfo.contentUrl;
          videoInfo.trustedVideoUrl = this.sanitizer.bypassSecurityTrustUrl(
            videoInfo.videoUrl
          );
          break;
        case 'mp4':
        case 'hls':
          videoInfo.videoUrl = videoInfo.contentUrl;
          videoInfo.trustedVideoUrl =
            this.sanitizer.bypassSecurityTrustResourceUrl(videoInfo.videoUrl);
          break;
        case 'vztube':
          videoInfo.externalId = this.videoService.getVzTubeIdByUrl(
            resource.url
          );
          if (videoInfo.externalId) {
            videoInfo.videoUrl = this.videoService.getVzTubeEmbedUrl(
              videoInfo.externalId,
              true
            );
            videoInfo.trustedVideoUrl = this.sanitizer.bypassSecurityTrustUrl(
              videoInfo.videoUrl
            );
          }
      }
    }
    this._videoInfo = videoInfo;
  }

  // Video helper methods ////

  private parseYouTubeId(url: string) {
    const regExp =
        /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/,
      match = url.match(regExp);

    if (match && match[1].length === 11) {
      return match[1];
    }
  }

  private getVideoType(url: string) {
    let videoType: VideoType = 'other';
    const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, url);

    if (sanitizedUrl) {
      if (
        sanitizedUrl.indexOf('youtu.be') > -1 ||
        sanitizedUrl.indexOf('youtube.com') > -1
      ) {
        videoType = 'youtube';
      } else if (
        sanitizedUrl.indexOf('vimeo.com') > -1 ||
        sanitizedUrl.indexOf('vimeocdn.com') > -1
      ) {
        videoType = 'vimeo';
      } else if (sanitizedUrl.indexOf('degreed://') > -1) {
        videoType = 'degreed'; // we are hosting this from uploader/recorder in CMS
      } else if (this.isMp4(sanitizedUrl)) {
        videoType = 'mp4';
      } else if (this.isHls(sanitizedUrl)) {
        videoType = 'hls';
      } else if (sanitizedUrl.indexOf('vztube.verizon.com') > -1) {
        videoType = 'vztube';
      }
    }
    return videoType;
  }

  private getVimeoEmbedUrl(
    vimeoKey: string,
    vimeoHash: string = null,
    autoplay: boolean = false
  ) {
    let urlString = '//player.vimeo.com/video/' + vimeoKey;
    const urlStringParts: string[] = [];

    if (vimeoHash) {
      urlStringParts.push(`h=${vimeoHash}`);
    }

    if (autoplay) {
      urlStringParts.push(`autoplay=1`);
    }

    if (urlStringParts.length > 0) {
      urlString = urlString + '?' + urlStringParts.join('&');
    }

    return urlString;
  }

  private getVimeoIdByUrl(url: string) {
    // These should mirror the patterns in the PatternMatchVimeoIdFromUrl method in VimeoHelper.cs
    // Account for vimeo.com/vimeocdn.com url beginning and query string/fragment end
    const idOnlyRegex = /^.+\.com\/([0-9]+)\/?(?:[\?#].*)?$/, // http://vimeo.com/1234 - id is 1234
      idAtStartRegex = /^.+\.com\/([0-9]+)\/\w+\/?(?:[\?#].*)?$/, // https://vimeo.com/1234/abcd789 - id is 1234
      channelRegex = /^.+\.com\/channels\/\w+\/([0-9]+)\/?(?:[\?#].*)?$/, // http://vimeo.com/channels/abcd789/1234 - id is 1234
      albumRegex = /^.+\.com\/album\/\w+\/video\/([0-9]+)\/?(?:[\?#].*)?$/; // http://vimeo.com/album/abcd789/video/1234 - id is 1234
    let vimeoId = '';
    const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, url);
    const match =
      sanitizedUrl.match(idOnlyRegex) ||
      sanitizedUrl.match(idAtStartRegex) ||
      sanitizedUrl.match(channelRegex) ||
      sanitizedUrl.match(albumRegex);
    if (match) {
      vimeoId = match[1];
    }
    return vimeoId;
  }

  private getVimeoHashByUrl(url: string) {
    let vimeoHash = null;
    const idAndHashRegex = /^.+\.com\/([0-9]+)\/\w+\/?(?:[\?#].*)?$/; // https://vimeo.com/1234/abcd789 - hash is abcd789
    if (url.match(idAndHashRegex)) {
      vimeoHash = url.split('/').pop();
    }
    return vimeoHash;
  }

  private getYoutubeEmbedUrl(youTubeKey: string, autoplay: boolean = false) {
    const youtubeHostname = this._youtubeEmbeddedVideoCookieEnabled
      ? 'www.youtube.com'
      : 'www.youtube-nocookie.com';
    return `https://${youtubeHostname}/embed/${youTubeKey}?rel=0&showinfo=0&autohide=1&wmode=transparent${
      autoplay ? '&autoplay=1' : ''
    }`;
  }

  private getYoutubeThumbnailUrl(youTubeKey: string) {
    return `https://img.youtube.com/vi/${youTubeKey}/hqdefault.jpg`;
  }

  private isMp4(url: string) {
    const parts = url.split('.'),
      extension = parts[parts.length - 1];
    if (
      extension.indexOf('mp4') === 0 ||
      extension.indexOf('m4v') === 0 ||
      extension.indexOf('mpeg4') === 0
    ) {
      return true;
    }
    return false;
  }

  private isHls(url: string) {
    const parts = url.split('.'),
      extension = parts[parts.length - 1];
    if (extension.indexOf('m3u8') === 0) {
      return true;
    }
    return false;
  }

  private getMetaResourceLabel() {
    if (this.targetIsRole) {
      return this.targetType;
    }
    if (this.targetIsDirectory) {
      return this.targetType;
    }
    if (this.resourceType) {
      const resourceTypeDisplay = this.displayTypePipe.transform(
        this.resourceType
      );
      return resourceTypeDisplay;
    }
    return;
  }

  private getTagsCountDisplay() {
    let tagsCountDisplay;
    if (this.targetIsRole && this.tagNames?.length) {
      tagsCountDisplay = this.translate.instant('dgInputStatistics_Tags', {
        count: this.tagNames.length,
      });
      return tagsCountDisplay;
    }
  }

  /**
   * Shallow copies the underlying model, performs a mutation action, then freezes the new model.
   * @description This helper method helps control mutations on the model. The original model
   * is frozen permanently upon construction of the view model so the states stay in sync. This
   * method allows the model to be recreated with a different state for some writeable view
   * model properties.
   */
  private refreezeModelWith(action: (model: LearningResource) => void) {
    this.model = { ...this.model };
    action(this.model);
    Object.freeze(this.model);
  }

  private stringToResourceID(input: string, prefix = 'Core') {
    const id = input
      .toLocaleLowerCase()
      .split(' ')
      .map((word) => capitalizeFirstLetter(word))
      .join('_');

    return `${prefix}_${id}`;
  }
}
