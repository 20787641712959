<ng-container *ngIf="vm$ | async as vm">
  <form
    *ngIf="!!videoCatalogForm"
    #formRef="ngForm"
    class="reactive-forms"
    [formGroup]="videoCatalogForm"
    (ngSubmit)="vm.isInitialForm ? onNext(formRef) : onSubmit()"
  >
    <dgx-modal
      [useDefaultForm]="false"
      [submitButtonText]="
        vm.isInitialForm ? i18n.Core_Next : i18n.MediaFormCtrl_SaveVideo
      "
      [canCancel]="true"
      [isSubmitDisabled]="vm.isSubmitButtonDisabled"
      [isSubmitPending]="vm.shouldSpinSubmitButton$ | async"
      [modalHeaderTitle]="heading"
      bodyClasses="guts-p-h-2 guts-p-v-2"
    >
      <!-- Modal body -->
      <ng-container class="modal-body">
        <!-- Created By -->
        <div
          *ngIf="videoCatalogForm.get('createdBy')?.value"
          class="reactive-forms__control reactive-forms__read-only"
        >
          <input
            class="par par--small"
            formControlName="createdBy"
            readonly="true"
            id="createdBy"
            data-dgat="video-catalog-6c6"
          />
        </div>

        <!-- URL input-->
        <div
          *ngIf="
            videoCatalogForm.get('entryUrl') &&
            !videoCatalogForm.get('contentUploader')?.value
          "
          class="reactive-forms__control"
        >
          <dgx-reactive-forms-label
            for="entryUrl"
            [isRequired]="!vm.isInitialForm && !isRecordedVideo"
          >
            <!--Only display the required text on full form page -->
            {{ i18n.MediaFormCtrl_VideoUrl }}
          </dgx-reactive-forms-label>
          <input
            #entryUrl
            autocomplete="off"
            type="url"
            id="entryUrl"
            formControlName="entryUrl"
            placeholder="http://"
            [dgxAutofocus]="true"
            data-dgat="video-catalog-ed6"
            [readonly]="isRecordedVideo"
            [ngClass]="{ 'bg-color-ebony-a08': isRecordedVideo }"
          />
          <!--Only display on the full form is isRecordedVideo -->
          <button
            *ngIf="isRecordedVideo"
            id="recordVideo"
            df-button
            [dfButtonType]="'clear'"
            data-dgat="videoForm-1a7"
            class="guts-m-full-1"
            (click)="loadRecordedVideoModal(true)"
          >
            <df-icon
              icon="video-camera"
              aria-hidden="true"
              class="color-blue guts-m-r-half"
            ></df-icon>
            <span class="color-blue">{{
              i18n.dgOrgInternalContentForm_ReRecordVideo
            }}</span>
          </button>
          <!-- Note http is the same for all countries -->
          <dgx-check-duplicate-inputs
            *ngIf="vm.duplicateCount"
            [duplicateCount]="vm.duplicateCount"
            (viewDuplicates)="openViewDuplicates()"
          >
          </dgx-check-duplicate-inputs>
          <dgx-validate-field
            class="block"
            *ngIf="validateEntryUrlError()"
            [message]="getUrlErrorMessage"
          >
          </dgx-validate-field>
        </div>
        <!-- Hosted Content uploader -->
        <div
          *ngIf="
            videoCatalogForm.get('contentUploader') &&
            videoCatalogForm.get('contentUploader')?.value !== null
          "
        >
          <!-- Only display the or if we are on the initial page  -->
          <div
            class="divider guts-m-t-1 guts-m-b-2"
            *ngIf="
              vm.isInitialForm &&
              !videoCatalogForm.get('contentUploader')?.value
            "
          >
            <span
              class="par par--small par--light up-me bg-color-white divider__content"
              >{{ i18n.Core_Or }}</span
            >
          </div>
          <dgx-upload-section
            [displayfileDocumentation]="vm.isInitialForm"
            [shouldDisableContentUploader]="vm.shouldDisableContentUploader"
            [inputType]="vm.inputType"
            [showReplaceButton]="!vm.isInitialForm"
            [fileInfo]="vm.file"
            [useCropper]="false"
            [uploadAdapter]="vm.uploadAdapter"
            [fileRestrictions]="vm.fileRestrictions"
            [showDeleteButton]="vm.isInitialForm"
            [errorMessages]="vm.errorMessages"
            [labels]="{ header: i18n.dgContentHosting_DragAndDrop }"
            icon="arrow-up-tray"
            (fileChangedEvent)="vm.contentUpload.onContentFileChange($event)"
            (uploadSuccessEvent)="
              vm.contentUpload.onContentUploadSuccess(
                videoCatalogForm,
                'contentUploader',
                $event
              )
            "
            (deleteEvent)="vm.contentUpload.onContentDelete(videoCatalogForm)"
            (errorEvent)="vm.contentUpload.onContentUploadFailure()"
          >
          </dgx-upload-section>
        </div>
        <!-- Record a video -->
        <div
          *ngIf="
            vm.shouldShowRecordVideoButton &&
            !videoCatalogForm.get('contentUploader')?.value &&
            vm.isInitialForm &&
            vm.videoConstraints.settings.usedDataPercent < 100
          "
        >
          <!-- Only display the or if we are on the initial page  -->
          <div class="divider guts-m-t-1 guts-m-b-2" *ngIf="vm.isInitialForm">
            <span
              class="par par--small par--light up-me bg-color-white divider__content"
              >{{ i18n.Core_Or }}</span
            >
          </div>

          <div class="l_flexbar">
            <div class="guts-p-t-quart l_flex-grow">
              <div class="form__label">
                <dgx-reactive-forms-label
                  for="recordVideo"
                  [isRequired]="!vm.isInitialForm"
                >
                  <!--Only display the required text on full form page -->
                  {{ i18n.dgOrgInternalContentForm_RecordAVideo }}
                  <div class="par par--small par--light">
                    {{
                      vm.videoConstraints
                        .dgOrgInternalContentForm_VideoTimeLimit
                    }}
                  </div>
                </dgx-reactive-forms-label>
              </div>
            </div>
            <button
              id="recordVideo"
              df-button
              [dfButtonType]="'clear'"
              data-dgat="videoForm-1a7"
              class="guts-m-full-1"
              (click)="loadRecordedVideoModal()"
            >
              <df-icon
                icon="video-camera"
                aria-hidden="true"
                class="color-blue guts-m-r-half"
              ></df-icon>
              <span class="color-blue">{{
                i18n.dgOrgInternalContentForm_Record
              }}</span>
            </button>
          </div>
        </div>
        <!-- EXPANDED FORM-->
        <ng-container *ngIf="showExpandedForm">
          <!-- Title -->
          <div class="reactive-forms__control">
            <dgx-reactive-forms-label for="title" [isRequired]="true">
              {{ i18n.Core_Title }}
            </dgx-reactive-forms-label>
            <input
              type="text"
              id="title"
              formControlName="title"
              [placeholder]="i18n.dgFlexRow_EditSectionTitlePlaceholder"
              [dgxAutofocus]="!!vm.hostedContentDetails"
              [attr.aria-describedby]="
                i18n.dgFlexRow_EditSectionTitlePlaceholder
              "
              data-dgat="video-catalog-009"
              (blur)="
                vm.loadInferredSkills(
                  videoCatalogForm.get('title').value,
                  videoCatalogForm.get('description').value
                )
              "
            />
            <dgx-validate-field
              class="block"
              *ngIf="
                videoCatalogForm.get('title').touched &&
                videoCatalogForm.get('title').invalid
              "
              [message]="i18n.MediaFormCtrl_TitleRequired"
            ></dgx-validate-field>
          </div>

          <!-- Source -->
          <ng-container *ngIf="vm.sourceName">
            <div class="reactive-forms__control reactive-forms__read-only">
              <dgx-reactive-forms-label for="sourceName">
                {{ i18n.Core_Source }}
              </dgx-reactive-forms-label>
              <input
                formControlName="sourceName"
                readonly="true"
                id="sourceName"
                data-dgat="video-catalog-f01"
              />
            </div>
          </ng-container>

          <!-- format -->
          <div class="reactive-forms__control">
            <dgx-reactive-forms-label for="format">
              {{ i18n.dgOrgInternalContentForm_VideoFormat }}
            </dgx-reactive-forms-label>
            <dgx-select
              class="full-width"
              [selectedItem]="{ title: vm.format }"
              (selection)="onFormControlUpdate('format', $event.title)"
              trackBy="title"
              [options]="vm.inputTypeFormats"
              labelKey="title"
              [placeholder]="
                i18n.dgOrgInternalContentForm_VideoFormatSelectPlaceholder
              "
              dgatInput="video-catalog-fd1"
            >
            </dgx-select>
          </div>

          <!-- Description  -->
          <div class="reactive-forms__control">
            <dgx-reactive-forms-label for="description">
              {{ i18n.Core_Description }}
            </dgx-reactive-forms-label>
            <textarea
              formControlName="description"
              [attr.maxlength]="3000"
              [attr.aria-label]="
                i18n.dgOrgInternalContentForm_VideoDescriptionPlaceholder
              "
              id="description"
              [placeholder]="
                i18n.dgOrgInternalContentForm_VideoDescriptionPlaceholder
              "
              data-dgat="video-catalog-c1c"
              (blur)="
                vm.loadInferredSkills(
                  videoCatalogForm.get('title').value,
                  videoCatalogForm.get('description').value
                )
              "
            ></textarea>
          </div>

          <!-- Duration -->
          <div class="reactive-forms__control">
            <dgx-duration
              [formModel]="{
                durationHours: vm.durationHours,
                durationMinutes: vm.durationMinutes,
              }"
            ></dgx-duration>
          </div>

          <!-- Content Owner -->
          <div class="form-wrap__group">
            <dgx-reactive-forms-label
              [for]="'owner'"
              [isRequired]="true"
              [icon]="'info'"
              [iconTooltip]="i18n.dgOrgInternalContentForm_ContentOwnerTooltip"
              [iconA11yText]="i18n.Core_MoreInfo"
            >
              {{ i18n.dgOrgInternalContentForm_ContentOwner }}
            </dgx-reactive-forms-label>
            <dgx-user-search
              id="owner"
              [useSearchField]="false"
              [recipients]="!!vm.owner ? [vm.owner] : []"
              [search]="userGroupListService.loadGroupsUsersRecommendees"
              [ariaLabel]="i18n.dgOrgInternalContentForm_ContentOwner"
              [allowRemoval]="true"
              (removeRecipient)="onContentOwnerChange(undefined)"
              (selectRecipient)="onContentOwnerChange($event)"
              [placeholder]="
                i18n.dgOrgInternalContentForm_ContentOwnerPlaceholder
              "
            ></dgx-user-search>
            <dgx-validate-field
              class="block"
              *ngIf="
                videoCatalogForm.get('owner').touched &&
                videoCatalogForm.get('owner').invalid
              "
              [message]="
                videoCatalogForm
                  .get('owner')
                  .hasError('validContentOwnerNeeded')
                  ? i18n.MediaFormCtrl_ValidContentOwnerRequired
                  : i18n.dgOrgInternalContentForm_ContentOwnerRequired
              "
            ></dgx-validate-field>
          </div>

          <!-- Image-->
          <div class="reactive-forms__control">
            <dgx-upload-section
              [imageUrl]="vm.imageUrl"
              [uploadAdapter]="vm.imageUploadAdapter"
              (imageParsedEvent)="vm.imageUpload.onParse($event)"
              (uploadSuccessEvent)="
                vm.imageUpload.onUpload(videoCatalogForm, $event)
              "
              (deleteEvent)="vm.imageUpload.onDelete(videoCatalogForm)"
              [useCropper]="true"
              [aspectRatio]="16 / 9"
              [secondaryAspectRatio]="16 / 5"
              [useBrowserReplace]="true"
            >
            </dgx-upload-section>
          </div>

          <!-- Skills-->
          <div class="reactive-forms__control">
            <dgx-tags-edit
              [hideHint]="true"
              [customTagToolTip]="i18n.dgOrgInternalContent_SkillsTooltipText"
              [customTitle]="i18n.Core_Skills"
              [topTags]="vm.mediumConfidenceInferredSkills"
              [hideTopTags]="!vm.mediumConfidenceInferredSkills?.length"
              [hideTooltip]="false"
              [tooltipIcon]="'info'"
              [placeholder]="
                vm.highConfidenceInferredSkills
                  ? i18n.dgOrgInternalContent_SkillsPlaceholderText
                  : ''
              "
              [allowExistingOnly]="false"
              [tags]="videoCatalogForm.get('skills').value"
              [showFullWidthLabel]="true"
              [useNewTagsDesign]="true"
              [useSkillProficiencyLevels]="useSkillProficiencyLevels"
              (tagsChange)="onFormControlUpdate('skills', $event)"
            ></dgx-tags-edit>
            <dgx-validate-field
              class="block"
              *ngIf="
                videoCatalogForm.get('skills').touched &&
                videoCatalogForm.get('skills').invalid
              "
              [message]="i18n.dgOrgInternalContent_SkillsMaxError"
            ></dgx-validate-field>
          </div>
          <!-- Advanced -->
          <div>
            <dgx-advanced-settings
              [formModel]="{
                visibility: vm.orgContentMetadata,
                publishedDate: vm.publishDate,
                internalItemId: vm.externalId,
                language: vm.language,
              }"
            ></dgx-advanced-settings>
          </div>
        </ng-container>
      </ng-container>
      <!-- Modal footer -->
      <ng-container class="modal-footer"> </ng-container>
    </dgx-modal>
  </form>
</ng-container>
